<app-menu></app-menu>

<div class="container" *ngIf="patientPath != undefined">

    <mat-card>{{patientPath.SID}}</mat-card>

    <mat-card class="date-card">Datum</mat-card>
    <mat-card class="activity-card">Aktivität</mat-card>

    <div class="timeline-wrapper" *ngFor="let element of pathView; let q = index">
        <mat-card class="rotated-card"><span class="rotated-text">{{element.stationName}}</span></mat-card>
        <div *ngFor="let act of pathView[q].activities" class="activity-item">
            <mat-card class="date">{{act.activityDate.toLocaleDateString()}}</mat-card>
            <mat-card class="activity">{{act.activityName}}</mat-card>
        </div>
        <!-- Empty card in case there is no entry (otherwise the units dont stack on one another)-->
        <div *ngIf="pathView[q].activities.length == 0" class="activity-item">
            <mat-card class="date">Keine Aktivität</mat-card>
        </div>
    </div>

</div>