import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GeneralService } from '../general.service';
import { environment } from 'src/environment/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  dashboardUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer, private generalService: GeneralService) {
    let url: string;
    if (environment.production) {
      url = 'https://sodiaph.de/landkarte/indexEt.html';
    } else if (environment.title === 'Demo Environment') {
      url = 'https://demo.sodiaph.de/landkarte/indexEt.html';
    } else {
      url = 'https://dev.sodiaph.de/landkarte/indexEt.html';
    }
    this.dashboardUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

  }

  ngOnInit(): void {
    this.generalService.setTitle('Medical Care Map');
    this.generalService.setSodiaphID('');
  }
}
