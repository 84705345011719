import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GeneralService } from '../general.service';
import { environment } from 'src/environment/environment';
import { enableProdMode } from '@angular/core';
import { UserService } from 'src/swagger-typescript';

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.css']
})
export class NetworkComponent implements OnInit {

  networkUrl: SafeResourceUrl | undefined;

  client: string = '';

  constructor(private sanitizer: DomSanitizer, private generalService: GeneralService, private userService: UserService) {

  }

  ngOnInit(): void {
    this.generalService.setSodiaphID('');

    // Fetch user details
    this.userService.v1UserOwnGet().subscribe({
      next: (user) => {
        this.client = user.username
        let url: string;

        if (environment.production) {

          url = 'https://sodiaph.de/network/' + this.client;
        } else if (environment.title === 'Demo Environment') {

          url = 'https://demo.sodiaph.de/network/' + this.client;
        } else {
          url = 'https://dev.sodiaph.de/network/' + this.client;
        }

        this.networkUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

      },
      error: (e) => console.error(e)
    });

  }
  
}