<div style="display: flex;">

        <button id="showInfo" style="color: #6b8294;" mat-icon-button (mouseover)="showInfo = true"
                (mouseleave)="showInfo = false">
                <mat-icon class="material-symbols-rounded">info</mat-icon>
        </button>

        <div style="color: white;" *ngIf="showInfo" class="tooltip">
                <h3>Erklärung zu Analysen</h3>

                Für die angegebenen Analysen werden die Datenübertragungsprozesse der einzelnen <b>Patientenpfade</b>
                betrachtet. <br><br>
                Für die einzelnen Patientenpfade können verschiedene <b>Kennzahlen</b> berechnet werden:<br>
                <ul>
                        <li><B>Digitalisierungsgrad:</B></li>
                        <ul>
                                <li>Beschreibt auf Grundlage der <b>Datenqualität</b> (Strukturiertheit) der Daten bei
                                        Übertragungsprozessen und dem Automatisierungsgrad der
                                        <b>Datenübertragungen</b>, den Grad der Digitalisierung über den gesamten
                                        Patientenpfad.
                                </li>
                        </ul>
                        <li><B>Datentransformation:</B></li>
                        <ul>
                                <li>Beschreibt in welchem Ausmaß sich die <b>Datenqualität</b> (Strukturiertheit) im
                                        Durchschnitt bei den Datenübertragungsprozessen eines Patientenpfads
                                        <b>verändert</b>.
                                </li>
                        </ul>
                </ul>

                Außerdem können die durchschnittliche Datenqualität und Automatisierung der Datenübertragung von
                Patientenpfaden unabhängig von diesen Kennzahlen betrachtet werden.<br>
                Auch die Betrachtung Gesamtverteilungen des Automatisierungsgrades der Datenübertragung sowie der
                Datentransformation aller <b>Einzelaktivitäten</b> in der Auswahl ist möglich. Hierbei werden
                <b>keine</b> Durchschnittswerte über Patientenpfade, sondern jede Datenübertragung als
                <b>individuelle</b> Aktivität betrachtet.
                <br><br>
                Mit <b>Filteroptionen</b> können Patientenpfade ausgewählt werden, auf welche die aktiven Filteroptionen
                zutreffen.
                Hierbei ist zu beachten, dass der <b>Ausschluss</b> einer Filteroption, <b>nicht</b> bedeutet, dass
                diese in den Daten nicht mehr vorkommt.
                Dies ist dennoch möglich, sofern die Option in mindestens <b>einem</b> Patientenpfad vorkommt, auf den
                die aktiven Optionen zutreffen.
                Beispielsweise können Patient*innen in Klinik A und B behandelt worden sein.
                Wenn dies der Fall ist, sind in einem Plot der nach "Klinik" unterscheidet, sowohl Daten zu Klinik A,
                als auch Klinik B zu sehen, selbst wenn nur eine der beiden Kliniken als Filteroption aktiv ist. Die
                Daten der Patientenpfade mit Aufenthalt in beiden Kliniken werden in diesem Fall sowohl für Klinik A,
                als auch B angezeigt. <br>
                Entsprechend verhält es sich mit den anderen Filter- und Unterscheidungsoptionen.
        </div>
        <mat-card *ngIf="!showInfo" class="custom-outline-card"
                style="flex: 1; margin-right: 5%; margin-bottom: 5%; margin-top: 2%; max-width: 400px;">
                <div class="custom-outline-label">Datenanalysen</div>
                <mat-card-content style="display: flex; flex-direction: column;">
                        <mat-label>Analyse-Auswahl</mat-label>
                        <mat-form-field style="margin-bottom: 5%;">
                                <mat-select [(ngModel)]="selectedAnalysis">
                                        <mat-option
                                                value="target_interoperability_index_vs_origin_interoperability_index"
                                                matTooltip="Streudiagramm zur Gesamtbetrachtung von Datenqualität und Datenübertragung">Digitalisierungsgrad
                                                Ursprung-Ziel</mat-option>

                                        <mat-option value="target_interoperability_index"
                                                matTooltip="Verteilung als Boxplot zur Gesamtbetrachtung von Datenqualität und Datenübertragung">Digitalisierungsgrad
                                                Ziel</mat-option>

                                        <mat-option value="origin_interoperability_index"
                                                matTooltip="Verteilung als Boxplot zur Gesamtbetrachtung von Datenqualität und Datenübertragung">Digitalisierungsgrad
                                                Ursprung</mat-option>

                                        <mat-option value="transformation_index"
                                                matTooltip="Streudiagramm zur durchschnittlichen Veränderung der Datenqualität">Durchschnittliche
                                                Datentransformation Ursprung-Ziel</mat-option>

                                        <mat-option value="transformation_score"
                                                matTooltip="Dichteverteilung der einzelnen Werte zur Veränderung der Datenqualität">Einzelaktivität
                                                Datentransformation Ursprung-Ziel</mat-option>

                                        <mat-option value="target_quality_index_vs_origin_quality_index"
                                                matTooltip="Streudiagramm zur durchschnittlichen Strukturiertheit der Daten">Durchschnittliche
                                                Datenqualität Ursprung-Ziel</mat-option>

                                        <mat-option value="origin_quality_index_vs_transmission_index"
                                                matTooltip="Streudiagramm zur durchschnittlichen Strukturiertheit der Daten und der Art des Datentransfers">Durchschnittliche
                                                Datenqualität
                                                Ursprung-Datenübertragung</mat-option>

                                        <mat-option value="transmission_index"
                                                matTooltip="Verteilung als Boxplot zur Art des Datentransfers">Durchschnittliche
                                                Datenübertragung</mat-option>

                                        <mat-option value="DATENÜBERTRAGUNG"
                                                matTooltip="Säulendiagramm zur Häufigkeit der Arten des Datentransfers">Einzelaktivität
                                                Datenübertragung</mat-option>

                                        <mat-option value="gender"
                                                matTooltip="Säulendiagramm zur Häufigkeit der Geschlechter unter den Patient*innen">Geschlecht</mat-option>

                                        <mat-option value="age_group"
                                                matTooltip="Säulendiagramm zur Häufigkeit von Altersgruppen unter den Patient*innen">Altersgruppe</mat-option>
                                </mat-select>
                        </mat-form-field>

                        <mat-label>Klinik</mat-label>
                        <mat-form-field>
                                <mat-select (selectionChange)="onClinicSelected($event)" [(ngModel)]="selectedClinic">
                                        <mat-option *ngFor="let clinic of clinics" [value]="clinic">
                                                {{ clinic.name }}
                                        </mat-option>
                                </mat-select>
                        </mat-form-field>

                        <mat-radio-group [(ngModel)]="aggregate" (change)="onRadioChange($event)">
                                <mat-radio-button
                                        matTooltip="Daten derselben Klinik werden zusammengefasst oder gleich eingefärbt"
                                        value="Klinik" [hidden]="isDATENUEBERTRAGUNG()">Unterscheidung nach
                                        Klinik</mat-radio-button>
                        </mat-radio-group>

                        <mat-label>Standort</mat-label>
                        <mat-form-field>
                                <mat-select (ngModelChange)="onLocationSelected()" [(ngModel)]="selectedLocations"
                                        multiple>
                                        <mat-option (click)="toggleSelectAll()" [value]="'selectAll'">
                                                {{ selectAllLocaltionsLabel }}
                                        </mat-option>
                                        <mat-option *ngFor="let location_name of location_names"
                                                [value]="location_name">
                                                {{location_name}}
                                        </mat-option>
                                </mat-select>
                        </mat-form-field>

                        <mat-radio-group [(ngModel)]="aggregate" (change)="onRadioChange($event)">
                                <mat-radio-button
                                        matTooltip="Daten desselben Standorts werden zusammengefasst oder gleich eingefärbt"
                                        value="primary_location" [hidden]="isDATENUEBERTRAGUNG()">Unterscheidung
                                        nach
                                        Standort</mat-radio-button>
                        </mat-radio-group>

                        <mat-label>Stationstyp</mat-label>
                        <mat-form-field>
                                <mat-select (ngModelChange)="onUnitTypeSelected()" [(ngModel)]="selectedUnitTypes"
                                        multiple>
                                        <mat-option (click)="toggleSelectAllUnitTypes()" [value]="'selectAllUnitTypes'">
                                                {{ selectAllUnitTypesLabel }}
                                        </mat-option>
                                        <mat-option *ngFor="let unit_type of unit_types" [value]="unit_type">
                                                {{unit_type}}
                                        </mat-option>
                                </mat-select>
                        </mat-form-field>

                        <mat-radio-group [(ngModel)]="aggregate" (change)="onRadioChange($event)">
                                <mat-radio-button
                                        matTooltip="Daten desselben Stationstyps werden zusammengefasst oder gleich eingefärbt"
                                        value="primary_unit" [hidden]="isDATENUEBERTRAGUNG()">Unterscheidung
                                        nach
                                        Stationstyp</mat-radio-button>
                        </mat-radio-group>

                        <mat-label>ICD-10 Codes</mat-label>
                        <mat-form-field>
                                <mat-select [(ngModel)]="selectedCodes" multiple>
                                        <mat-option (click)="toggleSelectAllCodes()" [value]="'selectAllCodes'">
                                                {{ selectAllCodesLabel }}
                                        </mat-option>
                                        <mat-option *ngFor="let icd_code of icd_codes" [value]="icd_code">
                                                {{icd_code}}
                                        </mat-option>
                                </mat-select>
                        </mat-form-field>
                        <mat-radio-group [(ngModel)]="aggregate" (change)="onRadioChange($event)">
                                <mat-radio-button
                                        matTooltip="Daten mit derselben Haupt- oder Aufnahmediagnose werden zusammengefasst oder gleich eingefärbt"
                                        value="Hauptdiagnose" [hidden]="isDATENUEBERTRAGUNG()">Unterscheidung
                                        nach ICD-10
                                        Codes</mat-radio-button>
                        </mat-radio-group>

                        <mat-radio-group [(ngModel)]="aggregate" (change)="onRadioChange($event)">
                                <mat-radio-button
                                        matTooltip="Daten zu Patient*innen desselben Geschlechts werden zusammengefasst oder gleich eingefärbt"
                                        value="gender"
                                        [hidden]="isGenderSelected() || isDATENUEBERTRAGUNG()">Unterscheidung
                                        nach
                                        Geschlecht</mat-radio-button>
                        </mat-radio-group>

                        <mat-radio-group [(ngModel)]="aggregate" (change)="onRadioChange($event)">
                                <mat-radio-button
                                        matTooltip="Daten zu Patient*innen derselben Altergruppe werden zusammengefasst oder gleich eingefärbt"
                                        value="age_group"
                                        [hidden]="isAgeSelected() || isDATENUEBERTRAGUNG()">Unterscheidung nach
                                        Altersgruppe</mat-radio-button>
                        </mat-radio-group>

                </mat-card-content>
                <mat-card-actions>
                        <button class="button_individual"
                                style="margin-left: 3%; margin-bottom: 4%; font-size: x-small; width: 30%; padding: 2%;"
                                mat-button (click)="resetFilters()">FILTER RESET</button>
                        <button class="button_individual"
                                style="margin-left: 35%; font-size: x-small; width: 30%; padding: 2%; margin-bottom: 4%;"
                                mat-button (click)="addAnalysis();">ANALYSE AUSFÜHREN</button>
                </mat-card-actions>
        </mat-card>

        <mat-card *ngIf="!showInfo" id="summary-card"
                style="flex: 1; margin-top: 2%; margin-right: 5%; margin-bottom: 5%;">
                <mat-card-content id="summary-card-content" style="overflow-y: auto; overflow-x: auto; color: #6b8294;">
                        <div *ngFor="let analysis of analyses; let i = index">
                                <div (click)="onAnalysisClick(i)">
                                        <div style="font-weight: bold;">
                                                Analyse {{ i + 1 }} - {{ getAnalysisName(analysis.selectedAnalysis) }}
                                        </div>
                                        Kliniken: {{ analysis.clinics }} <br>
                                        Standorte: {{ analysis.selectedLocations }} <br>
                                        ICD-10 Codes: {{ analysis.selectedCodes }} <br>
                                        Stationstyp: {{ analysis.selectedUnitTypes }} <br>
                                        Farbliche Hervorhebung nach: {{getHighlightingName(analysis.aggregate)}}<br><br>
                                </div>
                                <button style="background-color: #6b8294; font-size: x-small; width: 15%; padding: 2%; margin-left: 85%; margin-bottom: 1%;"
                                        mat-button (click)="showNetwork(i)"> <mat-icon
                                                class="material-symbols-rounded">insights</mat-icon>
                                        Netzwerk anzeigen</button>
                                <button style="background-color: #6b8294; font-size: x-small; width: 15%; padding: 2%; margin-left: 85%;"
                                        mat-button (click)="downloadSpec(i)">DOWNLOAD SPEC</button>
                                <hr>
                        </div>
                </mat-card-content>
        </mat-card>

</div>