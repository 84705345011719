/**
 * Sodiaph Backend
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AdmissionType } from './admissionType';
import { DiagnosisDTO } from './diagnosisDTO';
import { UnitType } from './unitType';

export interface PatientPathDownloadDTO { 
    id?: string;
    mandantId: string;
    created?: string;
    updated?: string;
    admission?: string;
    admissionType?: AdmissionType;
    SID?: string;
    age?: number;
    gender?: PatientPathDownloadDTO.GenderEnum;
    diagnoses: Array<DiagnosisDTO>;
    treatmentDays?: number;
    firstTreatmentUnitType?: UnitType;
}
export namespace PatientPathDownloadDTO {
    export type GenderEnum = 'm' | 'w' | 'd';
    export const GenderEnum = {
        M: 'm' as GenderEnum,
        W: 'w' as GenderEnum,
        D: 'd' as GenderEnum
    };
}